const joinUrlPath = (...segments) => segments.join('/').replace(/\/\/+/g, '/');

const PUBLIC_ASSETS_PATH = '/assets/ssr/';
const INTERNAL_ASSETS_ROOT = process.env.INTERNAL_ASSETS_ROOT || '/assets/';
const AMPLITUDE_API_KEY = process.env.AMPLITUDE_API_KEY || 'unknown';
const INTERNAL_ASSETS_PATH = joinUrlPath(INTERNAL_ASSETS_ROOT, '/ssr/');

module.exports = {
  GTM_CONTAINER_ID: 'GTM-T7QFMT',
  GTM_EXTRA_PARAMS: '',
  EVENTSTREAM_LIB_PATH: joinUrlPath(PUBLIC_ASSETS_PATH, '/eventstream-v0.0.13.js'),
  PUBLIC_ASSETS_PATH,
  INTERNAL_ASSETS_PATH,
  AMPLITUDE_API_KEY,
};
