import './initSentry';

import { EVENTSTREAM_LIB_PATH, GTM_CONTAINER_ID, GTM_EXTRA_PARAMS } from '../settings';

const preloadScript = (script: string) => {
  const link = document.createElement('link');
  link.href = script;
  link.rel = 'preload';
  link.as = 'script';

  if (document.head && document.head.appendChild) {
    document.head.appendChild(link);
  }
};

preloadScript(EVENTSTREAM_LIB_PATH);
preloadScript(`https://www.googletagmanager.com/gtm.js?id=${GTM_CONTAINER_ID}${GTM_EXTRA_PARAMS}`);
preloadScript('https://www.google-analytics.com/analytics.js');

import(/* webpackChunkName: "indexJsx" */ './index');
