import {
  dedupeIntegration,
  extraErrorDataIntegration,
  init,
  replayIntegration,
} from '@sentry/react';

import sentryBrowserFilterOptions from '@rover/utilities/sentryBrowserFilterOptions';

// Don't destructure because webpack.EnvironmentPlugin can't replace destructured vars
const SENTRY_DSN = process.env.SENTRY_DSN; // eslint-disable-line prefer-destructuring
const ROVER_ENVIRONMENT = process.env.ROVER_ENVIRONMENT || 'development';
const ENVIRONMENT = `${ROVER_ENVIRONMENT}-client`;

if (!SENTRY_DSN || !SENTRY_DSN.length) {
  console.error('SENTRY_DSN environment variable was not found'); // eslint-disable-line no-console
}

init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  release: process.env.RELEASE_ID || 'unknown',
  integrations: [dedupeIntegration(), extraErrorDataIntegration(), replayIntegration()],
  initialScope: {
    tags: {
      node_env: process.env.NODE_ENV,
    },
  },
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.01,
  ...sentryBrowserFilterOptions,
});
